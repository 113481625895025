import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const API_AGENZIA = '/dashboard/api/v0/azienda/view';

const TheContainer = () => import('@/containers/TheContainer');
const Dashboard = () => import('@/views/Dashboard');

// Totem
const GestioneTotem = () => import('@/views/funer24/gestionetotem/GestioneTotem');
const ListaTotem = () => import('@/views/funer24/gestionetotem/ListaTotem');

// Manifesti
const ListaManifesti = () => import('@/views/funer24/manifesti/ListaManifesti');
const Manifesto = () => import('@/views/funer24/manifesti/Manifesto');

// Necrologi
const ListaNecrologi = () => import('@/views/funer24/necrologi/ListaNecrologi');
const Necrologio = () => import('@/views/funer24/necrologi/Necrologio');

// Sito web - Azienda
const Homepage = () => import('@/views/funer24/website/Homepage');
const Chisiamo = () => import('@/views/funer24/website/Chisiamo');
const Contatti = () => import('@/views/funer24/website/Contatti');

// Casafuneraria
const CasaFuneraria = () => import('@/views/funer24/website/casefunerarie/Casafuneraria');
const ListaCasaFuneraria = () => import('@/views/funer24/website/casefunerarie/ListaCaseFunerarie');

// Faq
const Faq = () => import('@/views/funer24/website/Faq');

// Extra page
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Error = () => import('@/views/pages/Error');

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  fallback: true,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'totem',
          redirect: '/totem/lista',
          name: 'Totem',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'impostazioni',
              name: 'Impostazioni',
              component: GestioneTotem,
            },
            {
              path: 'lista',
              name: 'Lista',
              component: ListaTotem,
            },
          ],
        },
        {
          path: '/necrologi',
          redirect: '/necrologi/lista',
          name: 'Necrologi',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'aggiungi',
              name: 'necrologio',
              component: Necrologio,
            },
            {
              path: 'lista',
              name: 'listaNecrologi',
              component: ListaNecrologi,
            },
          ],
        },
        {
          path: '/manifesti',
          redirect: '/manifesti/lista',
          name: 'Manifesti',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'visualizza',
              name: 'manifesto',
              component: Manifesto,
            },
            {
              path: 'lista',
              name: 'listaManifesti',
              component: ListaManifesti,
            },
          ],
        },
        {
          path: '/sito',
          redirect: '/sito/homepage',
          name: 'Website',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'homepage',
              component: Homepage,
            },
            {
              path: 'chisiamo',
              component: Chisiamo,
            },
            {
              path: 'contatti',
              component: Contatti,
            },
            {
              name: 'listaCaseFunerarie',
              path: 'ListaCasaFuneraria',
              component: ListaCasaFuneraria,
            },
            {
              name: 'casaFuneraria',
              path: 'Casafuneraria',
              component: CasaFuneraria,
            },
            {
              path: 'faq',
              component: Faq,
            },
          ],
        },
      ],
    },

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404,
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
        },
        {
          path: 'error',
          name: 'Error',
          component: Error,
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/pages/login') {
    return next();
  }

  const host = window.location.protocol + '//' + window.location.host;

  if (localStorage.getItem('token') === null) {
    console.log('Token non presente, reindirizzamento al login');
    window.location.replace(host + '/#/pages/login');
    return next(false); // Interrompe la navigazione
  }

  try {
    window.axios.interceptors.request.use(function (config) {
      config.headers.common = {
        Authorization: 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return config;
    });

    // Verifica se il sistema è bloccato
    const res = await window.axios.get(API_AGENZIA);
    if (res.data.results[0].blocco_sistema) {
      console.log('Sistema bloccato, reindirizzamento alla pagina di errore');
      window.location.replace(host + '/#/pages/error');
      return next(false); // Interrompe la navigazione
    }

    return next(); // Procedi alla rotta richiesta
  } catch (e) {
    console.error('Errore durante la verifica:', e);

    // Se c'è un errore di autenticazione (401)
    if (e.response && e.response.status === 401) {
      localStorage.removeItem('token'); // Rimuovi il token non valido
      window.location.replace(host + '/#/pages/login');
      return next(false);
    }

    // Per altri errori, procedi comunque (ma potresti voler gestirli diversamente)
    return next();
  }
});

export default router;
