import common from './common';
const CREATE = '/dashboard/api/v0/azienda/sedi/cellulari/create';
const LIST = '/dashboard/api/v0/azienda/sedi/cellulari/list?id=';
const UPDATE = '/dashboard/api/v0/azienda/sedi/cellulari/update/';
const DESTROY = '/dashboard/api/v0/azienda/sedi/cellulari/destroy/';

export default {
  namespaced: true,
  state: {
    cellularisedi: [],
  },

  actions: {
    list({ commit }, id) {
      return axios.get(LIST + id).then(
        (response) => {
          if (response.status === 200) {
            commit('SET', response.data.results);
          }
        },
        (e) => {
          common.logError('list', e);
          return e.response.data;
        }
      );
    },

    create({ commit }) {
      return axios.post(CREATE, {}).then(
        (response) => {
          console.log(response);
          if (response.status === 201) {
            commit('CREATE', response.data);
          }
        },
        (e) => {
          common.logError('create', e);
          return e.response.data;
        }
      );
    },

    destroy({ commit }, item) {
      return axios.delete(DESTROY + item.id).then(
        (response) => {
          console.log(response);
          if (response.status === 204) {
            commit('REMOVE', item.id);
          }
        },
        (e) => {
          common.logError('destroy', e);
          return e.response.data;
        }
      );
    },

    update({ commit }, item) {
      return axios.patch(UPDATE + item.id, item).then(
        (response) => {
          console.log(response);
        },
        (e) => {
          common.logError('update', e);
          return e.response.data;
        }
      );
    },
  },

  mutations: {
    SET(state, result) {
      state.cellularisedi.push(result);
    },
    REMOVE(state, id) {
      state.cellularisedi = state.cellularisedi.filter((item) => item.id !== id);
    },
    CREATE(state, item) {
      state.cellularisedi.unshift(item);
    },
  },

  getters: {
    cellularisedi: (state) => state.cellularisedi,
  },
};
