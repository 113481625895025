import common from './common';

const GET = '/dashboard/api/v0/azienda/view';
const UPDATE = '/dashboard/api/v0/azienda/update/';

export default {
  namespaced: true,
  state: {
    agenzia: null,
  },

  actions: {
    get({ commit }) {
      return axios.get(GET).then(
        (response) => {
          if (response.status === 200) {
            const res = response.data.results[0];
            commit('GET', res);
            return true;
          }
        },
        (e) => {
          common.logError('get', e);
          return e.response.data;
        }
      );
    },

    update({ commit, state }, item) {
      return axios.patch(UPDATE + state.agenzia.id, item).then(
        (response) => {
          if (response.status === 200) {
            console.log(response);
            return true;
            //commit('UPDATE', response.data)
          }
        },
        (e) => {
          common.logError('update', e);
          return e.response.data;
        }
      );
    },
  },

  mutations: {
    GET(state, result) {
      console.log('GET_AGENCY', result);
      state.agenzia = result;
    },
    UPDATE(state, _item) {
      console.log('UPDATE_AGENCY', _item);
      state.agenzia = _item;
    },
  },

  getters: {
    agenzia: (state) => state.agenzia,
  },
};
