import common from './common';

const CREATE = '/dashboard/api/v0/stanzafunerarie/create';
const LIST = '/dashboard/api/v0/stanzafunerarie/list/';
const UPDATE = '/dashboard/api/v0/stanzafunerarie/update/';
const DESTROY = '/dashboard/api/v0/stanzafunerarie/destroy/';

export default {
  namespaced: true,
  state: {
    items: [],
  },

  actions: {
    list({ commit }, casafuneraria) {
      return axios.get(LIST + '?casa_funeraria=' + casafuneraria).then(
        (response) => {
          if (response.status === 200) {
            console.log('LIST', response.data);
            commit('SET', response.data.results);
          }
        },
        (e) => {
          common.logError('list', e);
          return e.response.data;
        }
      );
    },

    create({ commit }, item) {
      return axios.post(CREATE, { casa_funeraria: item.id }).then(
        (response) => {
          console.log(response);
          if (response.status === 201) {
            console.log('CREATE', response.data);
            commit('CREATE', response.data);
          }
        },
        (e) => {
          common.logError('create', e);
          return e.response.data;
        }
      );
    },

    delete({ commit }, item) {
      return axios.delete(DESTROY + item.id).then(
        (response) => {
          console.log('DELETE', response);
          if (response.status === 204) {
            commit('DELETE', item.id);
          }
        },
        (e) => {
          common.logError('delete', e);
          return e.response.data;
        }
      );
    },

    update({ commit }, item) {
      return axios.patch(UPDATE + item.id, item).then(
        (response) => {
          console.log('UPDATE', response);
        },
        (e) => {
          common.logError('update', e);
          return e.response.data;
        }
      );
    },
  },

  mutations: {
    SET(state, result) {
      state.items = result;
    },
    DELETE(state, id) {
      state.items = state.items.filter((faq) => faq.id !== id);
    },
    CREATE(state, faq) {
      state.items.push(faq);
    },
  },

  getters: {
    items: (state) => state.items,
  },
};
